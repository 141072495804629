import { PureComponent } from "react";
import { merge } from "./merge";
import AccessMap, { SEGMENT_SELECTION_MODE } from "./map";
import { DefaultColorScheme } from './color';
import { Map } from "immutable";
import { RingSpinnerOverlay } from 'react-spinner-overlay';
import { SEGMENT_JOURNEYS_PER_TRIP_TRANSFORMATION, SEGMENT_JOURNEYS_PER_SEGMENT_SERVICE_SECOND_TRANSFORMATION, SEGMENT_JOURNEYS_TRANSFORMATION, SEGMENT_JOURNEYS_PER_SERVICE_SECOND_TRANSFORMATION, getTransformedValues, INBOUND_OUTBOUND_SUM_TRANSFORMATION } from "./transformer";
import { Modal, Table } from "react-bootstrap";

class SegmentJourneysViewer extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            "segmentRecords": [],
            "viewport": {
                "latitude": 0.0,
                "longitude": 0.0,
                "zoom": 14,
            },
            "sectors": [],
            "centerSectors": [],
            "centerPoints": [],
            "hoveredSegment": undefined,
            "disambiguatingSegmentInfo": undefined,
            "selectedSegment": undefined,
            "expandingSegment": undefined,
            "segmentTransformation": SEGMENT_JOURNEYS_PER_TRIP_TRANSFORMATION,
            "sectorTransformation": INBOUND_OUTBOUND_SUM_TRANSFORMATION,
            "totalJourneys": 0,
            "sectorTransformations": undefined,
            "segmentTransformations": undefined,
            "showInfo": this.props.initialShowInfo
        }

        setInterval(() => {
            if (this.state.expandingSegment !== undefined) {
                this.selectSegment(this.state.expandingSegment);
            }
        }, 10_000);


        this.setViewport = (viewport) => {
            this.setState({ "viewport": viewport });
        }

        this.getCalculationPromise = () => {
            return this.props.scoreClient.getCalculation(
                this.props.calculationId,
                this.props.calculationPath).then((response) => {
                    return {
                        "calculation": response.data
                    };
                });
        };

        this.segmentJourneysPromise = () => {
            return this.props.scoreClient.getSegmentJourneys(
                this.props.calculationId,
                this.props.calculationPath,
                this.props.duration).then((response) => {
                    return {
                        "segmentJourneys": response.data
                    };
                });
        };


        this.getServiceLinestringsPromise =
            (serviceId) => {
                return this.props.rideClient.getServiceLinestrings(serviceId).then((response) => {
                    return {
                        "linestrings": response.data
                    };
                });
            };

        this.getServicePromise = (serviceId) => {
            return this.props.rideClient.getService(serviceId).then((response) => {
                return {
                    "service": response.data
                };
            });
        };

        this.getSectorsPromise = (grid) => {
            return this.props.gridClient.getSectors(grid).then((sectorRes) => {
                const reducer = (numberedSectors, sector) => {
                    numberedSectors[sector.id] = sector;
                    return numberedSectors;
                };

                const sectors = sectorRes.data.reduce(reducer, []);
                return { "sectors": sectors };
            });
        };

        this.getCenterPromise = (grid) => {
            return this.props.gridClient.getGrid(grid).then((res) => {
                const center = res.data.center;
                const parts = center.split(",");
                return {
                    "viewport": {
                        "zoom": this.state.viewport.zoom,
                        "latitude": parseFloat(parts[0]),
                        "longitude": parseFloat(parts[1])
                    }
                };
            })
        };

        this.getDetailsForRoutingsPromise = (serviceId) => {
            return this.props.rideClient.getDetailsForRoutings(serviceId)
                .then((response) => {
                    return {
                        "routingDetails": response.data
                    };
                });
        };

        this.getStopsPromise = (serviceId) => {
            return this.props.rideClient.getServiceStops(serviceId).then(
                (response) => {
                    return {
                        "stops": response.data
                    }
                });
        }

        this.getSegmentServiceTimesPromise = () => {
            return this.props.scoreClient.getSegmentServiceTimes(
                this.props.calculationId,
                this.props.calculationPath,
                this.props.duration
            ).then((response) => {

                const segmentServiceTimes = {};
                response.data.forEach(element => {
                    if (!(element.routingIndex in segmentServiceTimes)) {
                        segmentServiceTimes[element.routingIndex] = {}
                    }
                    segmentServiceTimes[element.routingIndex][element.segment] = element.serviceTime;
                });
                return {
                    "segmentServiceTimes": segmentServiceTimes
                };
            });
        }

        this.getRoutingServiceTimesPromise = () => {
            return this.props.scoreClient.getRoutingServiceTimes(
                this.props.calculationId,
                this.props.calculationPath,
                this.props.duration
            ).then((response) => {

                const routingServiceTimes = {};
                response.data.forEach(element => {
                    routingServiceTimes[element.index] =
                    {
                        "serviceTime": element.duration,
                        "tripCount": element.tripCount,
                    }
                });
                return {
                    "routingServiceTimes": routingServiceTimes
                };
            });
        }

        this.hoverSegment = (segmentInfo) => {
            this.setState({ "hoveredSegment": segmentInfo });
        };

        this.clickSegment = (segmentInfo) => {
            if (segmentInfo.segments.length === 1 &&
                (this.state.selectedSegment === undefined ||
                    (this.state.selectedSegment !== undefined &&
                        segmentInfo.segments[0].routingId !== this.state.selectedSegment.routing &&
                        segmentInfo.segments[0].sequence !== this.state.selectedSegment.sequence))) {
                const segment = segmentInfo.segments[0];

                this.setState({
                    "expandingSegment": segment
                });
                this.selectSegment(segment);


            } else if (segmentInfo.segments.length > 1) {
                this.setState({ "disambiguatingSegmentInfo": segmentInfo });
            }
            if (this.state.selectedSegment !== undefined) {
                this.setState({
                    "selectedSegment": undefined,
                    "sectorTransformations": undefined
                });
            }
        }

        this.selectSegment = (segment) => {
            const routing = segment.routingId;
            const sequence = segment.sequence;
            this.props.scoreClient.getSegmentSectors(
                this.props.calculationId,
                this.props.calculationPath,
                routing,
                sequence,
                this.props.duration).then((res) => {
                    const status = res.data.status;
                    const reaches = res.data.sectorReaches;
                    if (status === "COMPLETE") {
                        const transformedValues = getTransformedValues(
                            this.state.sectors,
                            reaches,
                            this.state.totalJourneys);
                        this.setState({
                            "selectedSegment": segment,
                            "sectorTransformations": transformedValues,
                            "expandingSegment": undefined,
                        });
                    } else if (status !== "ERROR") {
                        this.setState({
                            "expandingSegment": segment,
                        })
                    }
                });
        }

        this.clickRow = (segment) => {
            this.setState({
                "expandingSegment": segment
            });
            this.selectSegment(segment);
            this.terminateDisambiguation();
        }

        this.setTransformationType = (transformation) => {
            const type = (this.state.sectorTransformations !== undefined) ? "sectorTransformation" : "segmentTransformation";

            this.setState({ [type]: transformation });
        }

        this.terminateDisambiguation = () => {
            this.setState({ "disambiguatingSegmentInfo": undefined });
        }

        this.closeInfo = () => {
            this.setState({ "showInfo": false });
        }
    }

    componentDidMount() {
        Promise.all([
            this.getCalculationPromise(),
            this.segmentJourneysPromise(),
            this.getSegmentServiceTimesPromise(),
            this.getRoutingServiceTimesPromise()])
            .then((results) => {
                const result = merge(results);
                const serviceId = result.calculation.serviceId;
                const gridId = result.calculation.gridId;

                return Promise.all([
                    Promise.resolve(result),
                    this.getServiceLinestringsPromise(serviceId),
                    this.getDetailsForRoutingsPromise(serviceId),
                    this.getStopsPromise(serviceId),
                    this.getSectorsPromise(gridId),
                    this.getCenterPromise(gridId)])
            }).then((results) => {
                const result = merge(results);

                const centers = (this.props.calculationPath === "network") ?
                    result.calculation.sectorCount : (2 * result.calculation.centerCount);
                const totalJourneys = result.calculation.sectorCount * result.calculation.totalTimes * centers;

                const segmentRecords = result.segmentJourneys.segmentJourneys
                    .filter((record) => result.linestrings[record.routing].length > 0)
                    .map((record) => {
                        const routingIndex = record.routing;
                        const sequence = record.segment;
                        const linestring = result.linestrings[routingIndex];
                        const segmentPoints = linestring[sequence];

                        const details = result.routingDetails[routingIndex];
                        const routes = details.routes;
                        const beginning = details.beginningStop;
                        const end = details.endStop;
                        const stopCount = details.stopCount;

                        const segmentServiceTime = result.segmentServiceTimes[routingIndex][sequence];
                        const routingServiceTime = result.routingServiceTimes[routingIndex];
                        return {
                            "points": segmentPoints,
                            "journeys": record.count,
                            "routingId": routingIndex,
                            "routes": routes,
                            "beginningStop": result.stops[beginning].stopName,
                            "endStop": result.stops[end].stopName,
                            "stopCount": stopCount,
                            "sequence": sequence,
                            "tripCount": routingServiceTime.tripCount,
                            "serviceTime": segmentServiceTime,
                            "routingServiceTime": routingServiceTime.serviceTime,
                        }
                    });

                const state = {
                    "segmentRecords": segmentRecords,
                    "totalJourneys": totalJourneys,
                    "sectors": result.sectors,
                    "viewport": result.viewport
                };
                if (result.calculation.centerSectors) {
                    state["centerSectors"] = result.calculation.centerSectors;
                }
                if (result.calculation.centerPoints) {
                    state["centerPoints"] = result.calculation.centerPoints;
                }
                this.setState(state)
            });

    }

    render() {
        let journeyRatioMin = 0;
        let journeyRatioMax = 0;
        const journeyRatioValues = this.state.segmentRecords.map(record => {
            const value = record.journeys;
            if (value > journeyRatioMax) {
                journeyRatioMax = value;
            }
            return {
                "value": value,
                "points": record.points,
                "routingId": record.routingId,
                "routes": record.routes,
                "routingBeginningStop": record.beginningStop,
                "routingEndStop": record.endStop,
                "routingStopCount": record.stopCount,
                "sequence": record.sequence,
                "tripCount": record.tripCount,
                "serviceTime": record.serviceTime,
            };
        });

        let journeysPerTripMin = 0;
        let journeysPerTripMax = 0;
        const journeysPerTripValues = this.state.segmentRecords.map(record => {
            const value = record.journeys / record.tripCount;
            if (value > journeysPerTripMax) {
                journeysPerTripMax = value;
            }
            return {
                "value": value,
                "points": record.points,
                "routingId": record.routingId,
                "routes": record.routes,
                "routingBeginningStop": record.beginningStop,
                "routingEndStop": record.endStop,
                "routingStopCount": record.stopCount,
                "sequence": record.sequence,
                "tripCount": record.tripCount,
                "serviceTime": record.serviceTime
            };
        });

        let journeysPerSegmentServiceSecondMin = 0;
        let journeysPerSegmentServiceSecondMax = 0;
        const journeysPerSegmentServiceSecondValues = this.state.segmentRecords.map(record => {
            const value = record.journeys / record.serviceTime;
            if (value > journeysPerSegmentServiceSecondMax) {
                journeysPerSegmentServiceSecondMax = value;
            }
            return {
                "value": value,
                "points": record.points,
                "routingId": record.routingId,
                "routes": record.routes,
                "routingBeginningStop": record.beginningStop,
                "routingEndStop": record.endStop,
                "routingStopCount": record.stopCount,
                "sequence": record.sequence,
                "tripCount": record.tripCount,
                "serviceTime": record.serviceTime
            };
        });

        let journeysPerServiceSecondMin = 0;
        let journeysPerServiceSecondMax = 0;
        const journeysPerServiceSecondValues = this.state.segmentRecords.map(record => {
            const value = record.journeys / record.routingServiceTime;
            if (value > journeysPerServiceSecondMax) {
                journeysPerServiceSecondMax = value;
            }
            return {
                "value": value,
                "points": record.points,
                "routingId": record.routingId,
                "routes": record.routes,
                "routingBeginningStop": record.beginningStop,
                "routingEndStop": record.endStop,
                "routingStopCount": record.stopCount,
                "sequence": record.sequence,
                "tripCount": record.tripCount,
                "serviceTime": record.serviceTime
            };
        });

        const segmentTransformations = Map().withMutations(map => {
            map.set(SEGMENT_JOURNEYS_TRANSFORMATION, {
                "values": journeyRatioValues,
                "min": journeyRatioMin,
                "max": journeyRatioMax
            })
                .set(SEGMENT_JOURNEYS_PER_TRIP_TRANSFORMATION, {
                    "values": journeysPerTripValues,
                    "min": journeysPerTripMin,
                    "max": journeysPerTripMax
                })
                .set(SEGMENT_JOURNEYS_PER_SEGMENT_SERVICE_SECOND_TRANSFORMATION, {
                    "values": journeysPerSegmentServiceSecondValues,
                    "min": journeysPerSegmentServiceSecondMin,
                    "max": journeysPerSegmentServiceSecondMax
                })
                .set(SEGMENT_JOURNEYS_PER_SERVICE_SECOND_TRANSFORMATION, {
                    "values": journeysPerServiceSecondValues,
                    "min": journeysPerServiceSecondMin,
                    "max": journeysPerServiceSecondMax
                })
        });

        const transformation = (this.state.sectorTransformations !== undefined) ?
            this.state.sectorTransformation :
            this.state.segmentTransformation;

        let disambiguationContent = undefined;
        if (this.state.disambiguatingSegmentInfo !== undefined) {
            disambiguationContent = (
                <Table striped>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Route Name</th>
                            <th>Route Origin</th>
                            <th>Route Destination</th>
                            <th>Route Stop Count</th>
                            <th>Segment Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.disambiguatingSegmentInfo.segments.map(segment => {
                            return (
                                <tr key={segment.routingId + " /" + segment.sequence}
                                    onClick={(e) => this.clickRow(segment)}>
                                    <td><div style={{ float: "left", backgroundColor: segment.color, height: "30px", width: "30px", clear: "both" }} /></td>
                                    <td>{segment.label}</td>
                                    <td>{segment.routingBeginningStop}</td>
                                    <td>{segment.routingEndStop}</td>
                                    <td>{segment.routingStopCount}</td>
                                    <td>{Math.floor(segment.value).toLocaleString()}</td>
                                </tr>);
                        })}
                    </tbody>
                </Table >);
        }

        const disambiguationModal = (<Modal
            centered={true}
            show={this.state.disambiguatingSegmentInfo !== undefined}
            onHide={this.terminateDisambiguation}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Select a segment to expand:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {disambiguationContent}
            </Modal.Body>
        </Modal>)

        return (
            <>
                {disambiguationModal}
                <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
                    <RingSpinnerOverlay
                        loading={(this.state.expandingSegment !== undefined) || (this.state.segmentRecords.length === 0)}
                        color="#000000" />

                    <AccessMap
                        doubleClickZoom={false}
                        visible={true}
                        width="auto"
                        height="auto"
                        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                        colorScheme={new DefaultColorScheme()}
                        viewport={this.state.viewport}
                        setViewport={this.setViewport}
                        selectionMode={SEGMENT_SELECTION_MODE}
                        expandedSectorTransformations
                        ={[]}
                        baselineCenterPoints
                        ={[]}
                        baselineCenterSectors
                        ={[]}
                        minRouteZoom={0}
                        busy={false}
                        sectors={this.state.sectors}
                        calculationCenterSectors={this.state.centerSectors}
                        calculationCenterPoints={this.state.centerPoints}
                        selectSegment={this.clickSegment}
                        selectedSegment={this.state.selectedSegment}
                        hoverSegment={this.hoverSegment}
                        hoveredSegment={this.state.hoveredSegment}
                        transformationType={transformation}
                        segmentTransformations={Map(segmentTransformations)}
                        setTransformationType={this.setTransformationType}
                        sectorTransformations={this.state.sectorTransformations}
                    />
                </div >
            </>
        )
    }
}

export default SegmentJourneysViewer