import { deepDictMerge, merge } from './merge';
import MeasurementTypes from './measurement-types';
import { getTransformationsPromise, getTransformationsForContext, getTransformationMerge } from './transformer';
import { getTransformedValues } from './transformer';

import { Map, List, Set } from 'immutable';
import { getInServiceTimePromise } from './access-management';

export function getClearedCalculationState() {
    return {
        "id": undefined,
        "path": undefined,
        "name": undefined,
        "centerSectors": [],
        "centerPoints": [],
        "sectorValues": [],
        "sectorTransformations": Map(),
        "reachedInboundSectorCount": 0,
        "reachedOutboundSectorCount": 0,
        "times": 0,
        "centerCount": 0,
        "sectorCount": 0,
        "durations": List(),
        "startTime": undefined,
        "endTime": undefined,
        "earliestTime": undefined,
        "latestTime": undefined,
        "status": undefined,
        "serviceId": undefined,
        "aggregations": {},
        "networks": [],
        "stops": {},
        "linestrings": {},
        "routesForRoutings": {},
        "expansionStatus": undefined,
        "expandedSectorValues": [],
        "expandedSectorTransformations": Map(),
        "selectedDuration": undefined,
    };
}

export function loadCalculationsPromise(component) {
    const itemNames = {};
    const itemPaths = {};
    return component.props.unScoreIt.getCalculations().then((res) => {
        const calculations = res.data.map(calculation => {
            const id = calculation.id;
            itemPaths[id] = calculation.path;
            itemNames[id] = calculation.name;
            return id;
        });
        return {
            "calculationIds": calculations,
            "itemPaths": itemPaths,
            "itemNames": itemNames
        };
    });
}

export function getGridPromise(component, grid) {
    return Promise.all([
        getSectorsPromise(component, grid),
        getGridDataPromise(component, grid)])
        .then(merge);
}

export function getGridDataPromise(component, grid) {
    if (grid && grid !== component.state.gridId) {
        return component.props.unGridIt.getGrid(grid).then((gridRes) => {
            const gridName = gridRes.data.name;
            const gridCenter = gridRes.data.center;
            const centerCoordStrings = gridCenter.split(',');
            const lat = parseFloat(centerCoordStrings[0])
            const lon = parseFloat(centerCoordStrings[1])
            const viewport = {
                "latitude": lat,
                "longitude": lon,
                "zoom": 13,
            }

            return {
                "gridId": grid,
                "gridName": gridName,
                "gridCenter": gridCenter,
                "viewport": viewport
            };
        });
    }
    return Promise.resolve({
        "gridId": component.state.gridId,
        "gridName": component.state.gridName,
        "gridCenter": component.state.gridCenter,
        "viewport": component.state.viewport
    });
}

function getSectorsPromise(component, grid) {
    if (grid && grid !== component.state.gridId) {
        return component.props.unGridIt.getSectors(grid).then((sectorRes) => {
            const reducer = (numberedSectors, sector) => {
                numberedSectors[sector.id] = sector;
                return numberedSectors;
            };

            const sectors = sectorRes.data.reduce(reducer, []);

            return { "sectors": sectors };
        });
    }
    return Promise.resolve({
        "sectors": component.state.sectors
    });
}

function getStopsPromise(component, serviceId, calculationField) {
    let promise;
    if (serviceId) {
        promise = component.props.unRideIt.getServiceStops(serviceId).then((stopsRes) => {
            return {
                [calculationField]: {
                    "stops": stopsRes.data
                }
            }
        });
    } else {
        promise = Promise.resolve({
            [calculationField]: {
                "stops": []
            }
        })
    }

    return promise
}

function getServiceLinestringsPromise(component, serviceId, calculationField) {
    let promise;
    if (serviceId) {
        promise = component.props.unRideIt.getServiceLinestrings(serviceId).then((res) => {
            return {
                [calculationField]: {
                    "linestrings": res.data
                }
            }
        });
    } else {
        promise = Promise.resolve({
            [calculationField]: {
                "linestrings": []
            }
        });
    }

    return promise;
}
function getServiceRoutesForRoutingPromise(component, serviceId, calculationField) {
    let promise;
    if (serviceId) {
        promise = component.props.unRideIt.getServiceRoutesForRoutings(serviceId)
            .then((response) => {
                return {
                    [calculationField]: {
                        "routesForRoutings": response.data
                    }
                };
            });
    } else {
        promise = Promise.resolve({
            [calculationField]: {
                "routesForRoutings": []
            }
        });
    }
    return promise;
}


export function setCalculation(component, calculation, calculationPath) {
    getCalculationDataPromise(
        component, calculation, calculationPath, "calculation")
        .then((calculationState) => {
            const calculationId = calculationState.calculation.id;
            const path = calculationState.calculation.path;

            return Promise.all([Promise.resolve(calculationState),
            loadCalculationsPromise(component),
            getAggregationsPromise(
                component,
                calculationId,
                path,
                "calculation"),
            getGridPromise(component, calculationState.gridId),
            getCalculationServicePromise(component,
                calculationState.calculation.serviceId, "calculation"),
            getServiceLinestringsPromise(component, calculationState.calculation.serviceId, "calculation"),
            getServiceRoutesForRoutingPromise(component, calculationState.calculation.serviceId, "calculation"),
            getStopsPromise(component, calculationState.calculation.serviceId,
                "calculation"),
            getComparablesPromise(component, calculationState.gridId),
            getInServiceTimePromise(
                component,
                calculationId,
                path,
                calculationState.calculation.durations.get(0),
                "inServiceSeconds")]);
        }).then((states) => {
            const newState = merge(states);
            const promises = [Promise.resolve(newState)];
            if ("SECTOR_REACH" in newState.calculation.aggregations) {
                promises.push(getReachCountsPromise(
                    component,
                    newState.calculation.id,
                    newState.calculation.path,
                    "calculation",
                    newState.calculation.durations.get(0)))
            } else {
                promises.push(Promise.resolve({
                    "calculation": {
                        "sectorValues": [],
                        "status": "COMPLETE"
                    }
                }))
            }
            return Promise.all(promises);
        })
        .then((states) => {
            const newState = merge(states);
            const sectorTransformations = getTransformedValues(
                newState.sectors, newState.calculation.sectorValues,
                newState.calculation.times * newState.calculation.centerCount);
            newState.calculation.sectorTransformations = sectorTransformations;
            newState.sectorTransformations = sectorTransformations;
            newState.expandedSector = undefined;
            newState.selectedSector = undefined;

            const validTransformations = getTransformationsForContext(
                false, false, !!component.state.baseline.get("id"),
                false, isIntermediateStateExhaustive(newState.calculation));

            if (component.state.transformationType === undefined ||
                !validTransformations.includes(
                    component.state.transformationType)) {
                newState.transformationType = validTransformations[0];
            }

            newState.calculation = Map(newState.calculation);
            newState.baseline = Map(getClearedCalculationState());
            component.setState(newState);
        })
}

export function setCalculationView(component, calculation, calculationPath,
    baseline, baselinePath, frequentWalkshed) {

    const promises = [
        getCalculationDataPromise(component, calculation, calculationPath, "calculation"),
        getAggregationsPromise(component, calculation, calculationPath, "calculation"),
    ];

    if (frequentWalkshed) {
        promises.push(getFrequentWalkshedSectorsPromise(component, frequentWalkshed))
    }
    if (baseline && baselinePath) {
        promises.push(getCalculationDataPromise(component, baseline,
            baselinePath, "baseline"));
    }

    Promise.all(promises)
        .then((states) => {
            const calculationState = merge(states);
            const duration = calculationState.calculation.durations.get(0);

            const promises = [
                Promise.resolve(calculationState),
                getInServiceTimePromise(component, calculation, calculationPath, duration, "inServiceSeconds"),
                getGridPromise(component, calculationState.gridId),
                getCalculationServicePromise(component,
                    calculationState.calculation.serviceId, "calculation"),
                getStopsPromise(component, calculationState.calculation.serviceId, "calculation"),
                getServiceLinestringsPromise(component, calculationState.calculation.serviceId, "calculation"),
                getServiceRoutesForRoutingPromise(component, calculationState.calculation.serviceId, "calculation"),
            ];

            if (baseline && baselinePath) {
                const baselineDuration = calculationState.baseline.durations.get(0);
                promises.push(getReachCountsPromise(component,
                    baseline,
                    baselinePath,
                    "baseline",
                    baselineDuration));
                promises.push(getCalculationServicePromise(component,
                    calculationState.baseline.serviceId, "baseline"));
                promises.push(getInServiceTimePromise(
                    component,
                    baseline,
                    baselinePath,
                    baselineDuration,
                    "baselineInServiceSeconds"));
            }


            if ("SECTOR_REACH" in calculationState.calculation.aggregations) {
                promises.push(getReachCountsPromise(
                    component,
                    calculationState.calculation.id,
                    calculationState.calculation.path,
                    "calculation",
                    duration))
            } else {
                // TODO: Kind of a hack.
                promises.push(Promise.resolve({
                    "calculation": {
                        "sectorValues": [],
                        "status": "COMPLETE"
                    }
                }))
            }
            return Promise.all(promises);
        }).then((states) => {
            const calculationState = merge(states);
            const transformationsPromise = getCalculationTransformationsPromise(
                calculationState, "calculation");

            let baselineTransformationPromise;
            if (calculationState.baseline) {
                baselineTransformationPromise = getCalculationTransformationsPromise(
                    calculationState, "baseline")
            } else {
                baselineTransformationPromise = Promise.resolve({});
            }

            return Promise.all([Promise.resolve(calculationState),
                transformationsPromise, baselineTransformationPromise]);
        }).then(states => {
            const newState = merge(states);

            let validTransformations;
            if (newState.baseline) {
                newState.sectorTransformations
                    = mergeCalculationStateTransformations(newState);
                validTransformations = getTransformationsForContext(false, false, true, false,
                    isIntermediateStateExhaustive(newState.baseline)
                    && isIntermediateStateExhaustive(newState.calculation));
            } else {
                newState.sectorTransformations
                    = newState.calculation.sectorTransformations;
                validTransformations = getTransformationsForContext(false, false, false, false,
                    isIntermediateStateExhaustive(newState.calculation));
            }

            newState.calculation = component.state.calculation.merge(newState.calculation);
            newState.baseline = component.state.calculation.merge(newState.baseline);

            newState.transformationType = validTransformations[0];

            component.setState(newState);
        })
}

export function getComparablesPromise(component, grid) {
    if (grid && (!component.state.comparableCalculations.length
        || (grid !== component.state.gridId))) {
        return component.props.unScoreIt.getComparables(grid).then((res) => {
            const listItems = res.data.map(calculation => {
                return {
                    "id": calculation.id,
                    "name": calculation.name,
                    "path": calculation.path
                };
            });
            return { "comparableCalculations": listItems };
        });
    }
    return Promise.resolve({
        "comparableCalculations": component.state.comparableCalculations
    });
}

export function getNetworkPromise(component, networkId) {
    return component.props.unRideIt.getNetwork(networkId).then((response) => {
        return { "id": networkId, "name": response.data.name };
    })
}

export function getCalculationServicePromise(component, service, calculationField) {
    let promise;
    if (service) {
        promise = component.props.unRideIt.getService(service).then((serviceRes) => {
            const networkIds = serviceRes.data.networkIds;
            const networkPromises = networkIds.map(id => {
                return getNetworkPromise(component, id);
            })
            return Promise.all(networkPromises);
        }).then(networks => {
            return {
                [calculationField]: {
                    "serviceId": service,
                    "networks": networks
                }
            };
        });
    } else {
        promise = Promise.resolve({
            [calculationField]: {
                "networks": []
            }
        })
    }


    return promise
}

function getFrequentWalkshedSectorsPromise(component, frequentWalkshedId) {
    return component.props.unScoreIt.getFrequentWalkshed(frequentWalkshedId).then((res) => {
        const data = res.data;
        const sectors = Set([...data.inbound, ...data.outbound]);
        const stops = Set(data.stops)
        return {
            "frequentWalkshedSectors": sectors,
            "frequentStops": stops,
        }
    })
}

export function getCalculationDataPromise(component, calculation, calculationPath, calculationField) {
    if (calculation && calculation !== component.state[calculationField].get('id')) {
        return component.props.unScoreIt.getCalculation(calculation, calculationPath)
            .then((res) => {
                const gridId = res.data.gridId;
                const serviceId = res.data.serviceId;
                const calculationName = res.data.name
                const calculationTimes = res.data.totalTimes
                const durations = res.data.durations;
                const startTime = res.data.startTime;
                const endTime = res.data.endTime;
                const sectorCount = res.data.sectorCount;

                const calculationState = {
                    "id": calculation,
                    "path": calculationPath,
                    "name": calculationName,
                    "durations": List(durations),
                    "startTime": startTime,
                    "endTime": endTime,
                    "times": calculationTimes,
                    "sectorCount": sectorCount,
                    "selectedDuration": durations[0],
                }

                if (serviceId) {
                    calculationState["serviceId"] = serviceId;
                }

                const fullState = {
                    "gridId": gridId,
                    [calculationField]: calculationState
                };


                let calculationTypeState;
                if (calculationPath === MeasurementTypes.POINT_ACCESS) {
                    const centerCount = res.data.centerCount;
                    calculationTypeState = {
                        [calculationField]: {
                            "centerCount": centerCount,
                            "centerPoints": res.data.centerPoints,
                            "centerSectors": res.data.centerSectors,
                        }
                    };
                } else if (calculationPath === MeasurementTypes.NETWORK_ACCESS) {
                    const sectorCount = res.data.sectorCount;
                    calculationTypeState = {
                        [calculationField]: {
                            "centerCount": sectorCount,
                            "centerPoints": [],
                            "centerSectors": [],
                        }
                    };
                }
                const newState = deepDictMerge(
                    fullState, calculationTypeState);

                return newState;
            });
    }
    return Promise.resolve({
        "gridId": component.state.gridId,
        "serviceId": component.state.serviceId,
        "earliestTime": component.state.earliestTime,
        "latestTime": component.state.latestTime,
        [calculationField]: component.state[calculationField]
    });
}

export function getReachCountsPromise(component, calculationId, calculationPath,
    calculationField, duration) {
    let reachCountPromise;

    const patch = component.props[calculationField + "Patch"];
    if (patch) {
        reachCountPromise = component.props.unScoreIt.getPatchReachCounts(calculationId, calculationPath, duration, patch);
    } else {
        reachCountPromise = component.props.unScoreIt.getReachCounts(calculationId, calculationPath, duration, undefined);
    }

    return reachCountPromise
        .then((res) => {
            let totalInbound = 0;
            let totalOutbound = 0;

            const reachList = res.data.sectorReaches;
            const status = res.data.status;

            reachList.forEach(reach => {
                const outbound = reach.outboundCount;
                totalOutbound += outbound;
                const inbound = reach.inboundCount;
                totalInbound += inbound;
            });
            return {
                [calculationField]: {
                    "sectorValues": reachList,
                    "reachedInboundSectorCount": totalInbound,
                    "reachedOutboundSectorCount": totalOutbound,
                    "status": status
                }
            };
        });
}

function getCalculationTransformationsPromise(calculationState,
    calculationField) {

    const sectorValues = calculationState[calculationField].sectorValues;

    return getTransformationsPromise(calculationState.sectors, sectorValues,
        calculationState[calculationField].times
        * calculationState[calculationField].centerCount,
        calculationField, "sectorTransformations");
}


export function mergeCalculationStateTransformations(calculationState) {
    const baselineTransformations
        = calculationState.baseline.sectorTransformations;
    const transformations
        = calculationState.calculation.sectorTransformations;

    return getTransformationMerge(calculationState.sectors, transformations,
        baselineTransformations);
}


function getAggregationsPromise(component, calculation, calculationPath,
    calculationField) {
    if (calculation && calculation
        !== component.state[calculationField].get('id')) {
        return component.props.unScoreIt.getAggregationStatuses(
            calculation, calculationPath).then(res => {

                return {
                    [calculationField]: {
                        "aggregations": res.data
                    }
                };
            });
    } else {
        return Promise.resolve({});
    }
}

export function isEntirelyExhaustive(calculation, baseline) {
    const calculationExhaustive = isExhaustive(calculation);
    const baselineExhaustive
        = baseline.get("id") ? isExhaustive(baseline) : true;
    return calculationExhaustive && baselineExhaustive;
}

export function isExhaustive(calculation) {
    return calculation.get("path") === "network";
}

export function isIntermediateStateExhaustive(calculation) {
    return calculation.path === "network";
}
